@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .footer-container {
        @apply
            bg-black
            h-auto
            text-white
            text-lg
            grid place-items-center
            static
            z-10      
    }

    .footer-content-container {
        @apply
            md:inline-flex
            p-8
            grid-cols-3
    }
}