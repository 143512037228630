@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .footer-card-container {
        @apply
            w-[200px]
            h-auto
            py-6
            m-5
    }

    .footer-header {
        @apply
            text-white
            text-xl
            text-center
            font-bold
    }

    .footer-items {
        @apply
            p-2
            text-lg
            text-center
    }
    
    .footer-items li {
        @apply
        hover:text-[#8800C8]
        cursor-pointer
    }

}