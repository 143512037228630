@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .info-container {
        @apply
            w-full
            md:h-[100vh]
            bg-[#151515]
            block
            md:grid items-center
            text-white
            p-6
    }

    .info-content-container {
        @apply
            w-full
            text-center
            md:grid place-items-center
    }

    .info-header {
        @apply
            text-white
            text-5xl
            py-6
    }

    .info-card-container {
        @apply
            p-4
            m-6
            md:grid-cols-3
            md:inline-flex
    }

    .info-content-container h3 {
        @apply
            md:w-[575px]
            text-xl
    }
}