@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   .navbar-container {
     @apply
      bg-black/50
        w-full
        h-[90px]
        text-white
        fixed
        z-50
   } 

   .inner-container {
     @apply
        max-w-[1240px] 
        mx-auto
        px-4
        flex
        justify-between
   }

   .logo-header {
     @apply
        text-3xl
        text-[#8800C8]
        font-extrabold
        grid items-center
        drop-shadow-[0px_2px_2px_rgba(0,0,0,0.80)]
   }

   .primary-button {
     @apply
        px-6
        py-2
        bg-gradient-to-r from-[var(--purple-dark)] via-[var(--purple-medium)] to-[var(--pirple-light)]
        rounded-xl
        drop-shadow-[0px_2px_2px_rgba(0,0,0,0.80)]
   }

   .right-container {
     @apply
        md:flex
        items-center
        hidden   
   }

   .items-container {
     @apply
        flex
        p-4
   }

   .items-container li {
     @apply
        p-4
        text-xl
        hover:text-[#8800C8]
        drop-shadow-[0px_2px_2px_rgba(0,0,0,0.80)]
        cursor-pointer
   }

   .mobile-icons-container {
      @apply
         p-8
         flex
         items-center
         text-3xl
         md:hidden
         drop-shadow-[0px_2px_2px_rgba(0,0,0,0.80)]
   }

   .mobile-menu-container {
      @apply
         text-white
         bg-black/80
         absolute
         z-30
         w-full
         top-[90px] left-0
         md:hidden
         pb-12
   }

   .mobile-items-container {
      @apply
         bg-black/10
         text-center
         drop-shadow-[0px_2px_2px_rgba(0,0,0,0.80)]
   }

   .mobile-items-container li {
      @apply
         py-4
         text-2xl
         hover:bg-[#8800C8]
         cursor-pointer
   }
}