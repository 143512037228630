@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .hero-container {
        @apply 
            w-full
            h-[90vh]
            top-0
    }

    .hero-content-container {
        @apply
            text-white
            bg-black/50
            h-[100vh]
            grid items-center
    }

    .hero-content {
        @apply
            grid justify-center
            text-white
            p-6
    }

    .hero-content h1{
        @apply
            text-6xl
            text-center
    }

    .hero-content h2{
        @apply
            text-4xl 
            text-center
            my-6
            font-extrabold
    }

    .hero-content p {
        @apply
            text-3xl
            text-center
    }

    .button-container {
        @apply
            text-center
    }

    .sign-up-button {
        @apply
            px-10
            py-4
            bg-gradient-to-r from-[var(--purple-dark)] via-[var(--purple-medium)] to-[var(--pirple-light)]
            rounded-xl
            drop-shadow-[0px_2px_3px_rgba(0,0,0,0.80)]
    }

    .learn-more-button {
        @apply
            px-7
            py-4
            bg-transparent
            border
            rounded-xl
            drop-shadow-[0px_2px_3px_rgba(0,0,0,0.80)]
    }
}