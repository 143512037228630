@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
*, *::before, *::after {
    box-sizing: border-box;
}

:root {
    --purple-color1: #380070;
    --purple-color2: #450089;
    --purple-color3: #4b0b89;
}

#circle1{
    fill: var(--purple-color1);
    opacity: 0.4;
}

#circle2{
    fill: var(--purple-color2);
    opacity: 0.5;
}

#circle3{
    fill: var(--purple-color3);
    opacity: 0.6;
}

body {
    margin: 0px;
    padding: 0px;
}


@layer base {
    * {
        @apply
        font-[Roboto]
    }

    .developers-container {
        @apply
        w-full
        h-[90vh]
        top-0
        bg-[url('assets/bg1.png')]
    }

    .developers-content-container {
        @apply
        text-white
        h-[90vh]
        pt-[5.5em]
        bg-black/70
    }
    .developers-grid-container {
        @apply
        md:grid grid-cols-2
        h-[80.5vh]
    }

    .developers-right-container {
        @apply
        m-auto
        p-8

    }
    .developers-left-container {
        @apply
        m-auto
    }

    .img2-style {
        @apply
        w-[600px]
    }

    .community-page-container {
        @apply
        w-full
        h-[100vh]
        bg-black
    }

    .circle-container {
        @apply
        h-[80vh]
        w-full
        flex
        place-items-center
        justify-center
        object-cover
    }

    #circle1 {
        animation: circleMotion1 8s ease-in-out infinite;
        
    }

    @keyframes circleMotion1 {
        20% {
            opacity: 0.5;
            transform: rotateX(15deg) rotateX(-10deg);
        }
        50%{
            opacity: 0.7;
            transform: translateX(30%) rotateX(10deg) rotateY(20deg);
        }
        100%{
            opacity: 0.4;
            transform: rotateY(90deg) rotateY(20deg) rotateX(40deg) rotateX(0deg);
        }
    }

    #circle2 {
        animation: circleMotion2 10s ease-in-out infinite;
        
    }

    @keyframes circleMotion2 {
        20% {
            opacity: 0.3;
            transform: rotateX(5deg) rotateX(-10deg) rotateY(5deg);
        }
        50%{
            opacity: 0.7;
            transform: rotateX(15deg) rotateY(120deg) rotateX(-5deg) translateY(10%);
        }
        100%{
            opacity: 0.4;
            transform: rotateX(5deg) rotateY(5deg) rotateX(5deg);
        }
    }

    #circle3 {
        animation: circleMotion3 9s ease-in-out infinite;
        
    }

    @keyframes circleMotion3 {
        20% {
            opacity: 0.5;
            transform: translateY(20%) rotateX(100deg) rotateY(-10deg);
        }
        50%{
            opacity: 0.6;
            transform: translateX(40%) rotateY(100deg) rotateX(45deg) rotateZ(-10deg);
        }
        100%{
            opacity: 0.5;
            transform: rotateX(45deg) rotateX(10deg) rotateY(5deg);
        }
    }

    .about-container {
        @apply
        bg-[#0d0d0d]
            h-[90vh]
            p-[2em]
            relative
            text-white
            flex
            place-items-center
    }

    .about-content-container {
        @apply
            md:h-[70vh]
            md:p-[6em]
            md:flex
            md:place-items-center
    }
    .about-page-content {
        @apply
        bg-black
            p-6
            md:p-[5em]
            shadow-[0_5px_15px_5px_rgba(0,0,0,0.4)]
    }
}