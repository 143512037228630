@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-purple: #8800C8;
    --secondary-dark: #151515;
    
    --purple-dark:#6100FF;
    --purple-medium:#9B0BF4;
    --pirple-light: #F81CE2;

}

@layer base {

}