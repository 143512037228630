@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .community-container {
        @apply
            p-6
            bg-black
            w-full
            h-[100vh]
            text-white
            text-2xl
            grid items-center
    }

    .community-content-container {
        @apply
            p-4
            text-center
            grid place-items-center
    }

    .image-container {
        @apply
            w-full
            h-auto
            grid place-items-center
    }

    .community-image-style {
        @apply
            w-[400px]
            mt-12
            shadow-lg
            shadow-purple-900
    }

    p span {
        @apply
        text-[#8800C8]
    }
}