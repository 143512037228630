@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .info-card {
        @apply
            bg-black
            border
            p-6
            h-auto
            w-[300px]
            text-white
            text-5xl
            m-4
            hover:scale-110
            hover:transition-all 
            hover:ease-out
            rounded-3xl
            shadow-[0_5px_15px_5px_rgba(0,0,0,0.4)]
    }

    .icon-container {
        @apply
            grid place-items-center
    }

    .icon-bg {
        @apply
        bg-gradient-to-r from-[var(--purple-dark)] via-[var(--purple-medium)] to-[var(--pirple-light)]
        w-[80px]
        h-[80px]
        grid place-items-center
        rounded-full
    }

    .title {
        @apply
            text-2xl
            font-bold
            text-left
            p-2
            mt-6
    }

    .info {
        @apply
            p-4
            text-xl
            text-left
    }
}