@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .join-container {
        @apply
            bg-[#151515]
            md:h-[60vh]
            text-white
            md:grid items-center
            p-12
    }

    .join-content-container {
        @apply
            md:grid justify-center
            text-center
    }

    .join-header {
        @apply
            md:text-4xl
            text-2xl
    }

    .subscribe-container {
        @apply
            mt-2
            text-black
            w-full
    }

    .email-input {
        @apply
            h-[60px]
            rounded-lg
            p-4
            w-full
            md:w-[400px]
            text-xl
    }

    .checkbox {
        @apply
            mr-6
            w-4
            h-[25px]
    }

    .disclaimer-items {
        @apply
          inline-flex
          p-6
    }
}